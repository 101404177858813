@charset "utf-8";
.prof-sub{
  font-weight: bold;
}
.prof-name{
  font-size: 1.625rem;
  font-weight: bold;
  line-height: 1;
}
.prof-txt{
  margin-top: 1em;
}

//table-campany
.table-campany {
  width: 100%;
  th {
    vertical-align: top;
    width: 20%;
    padding: 10px;
    border-bottom: 1px solid #b7b7b7;
    @media (max-width: 767px) {
      display: block;
      width: 100%;
      padding: 10px 0 5px;
    }
  }
  td {
    padding: 10px;
    border-bottom: 1px solid #b7b7b7;
    @media (max-width: 767px) {
      display: block;
      padding: 10px 0 5px;
    }
  }
  tr:first-of-type th,
  tr:first-of-type td {
    border-top: 1px solid #b7b7b7;
    @media (max-width: 767px) {
      border-top: 0;
    }
  }
  .bdb-0 > td {
    border-bottom: 0;
  }
}

//timeline
.timeline {
  list-style: none;
}
.timeline > li {
  margin-bottom: 60px;
}

.timeline > li {
  overflow: hidden;
  margin: 0;
  position: relative;
}
.timeline-date {
  font-weight: bold;
  width: 15%;
  float: left;
}
.timeline-content {
  width: 75%;
  float: left;
  border-left: 3px #e5e5d1 solid;
  padding-left: 30px;
  padding-bottom: 30px;
}
.timeline-content:before {
  content: '';
  width: 12px;
  height: 12px;
  background: #ED6D00;
  position: absolute;
  left: calc(15% - 5px);
  top: 8px;
  border-radius: 100%;
}
