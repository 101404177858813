@charset "utf-8";

// class

.font-en {
  font-weight: 900;
  letter-spacing: 0.13em;
}

//pr
.pr {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
//  background: url(../img/top/pr_bg.jpg) no-repeat top center;
  background-size: cover;
  width: 100vw;
  height: 100vh;
	max-width: 100%;
}
.pr__contnts {
  color: #fff;
  text-align: center;
}
.pr__ttl__en {
  font-weight: 900;
  font-size: 2.375rem;
  letter-spacing: 0.13em;
  line-height: 1;
  margin-bottom: 0.476em;
  opacity: 0.9;
  @media (min-width: 520px) {
    font-size: 3.9375rem;
  }
}
.pr__ttl {
  font-size: 1.285rem;
  font-weight: bold;
  letter-spacing: 0.13em;
  line-height: 1.5;
  position: relative;
  margin-bottom: 2.733em;
  @media (min-width: 630px) {
    font-size: 1.875rem;
  }
  &::after {
    content: "";
    background: #ed6d00;
    height: 2px;
    width: 24px;
    margin-left: -12px;
    position: absolute;
    bottom: -1.333em;
    left: 50%;
  }
}
.pr__read {
  letter-spacing: 0.1em;
}
.pr__scroll {
  background: #fff;
  display: block;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-left: -24px;
  position: absolute;
  left: 50%;
  bottom: 30px;
  &::after {
    content: "";
    display: block;
    width: 14px;
    height: 14px;
    border: 2px solid;
    border-color: transparent transparent #ed6d00 #ed6d00;
    transform: rotate(-45deg);
    margin: 13px auto 0;
    transition: all;
  }
  &:hover {
    &::after {
      margin: 15px auto 0;
    }
  }
}

/*============================
top mission
============================*/

.top-sec {
  padding: 30px 0 40px;
  @media (min-width: 768px) {
    padding: 50px 0 70px;
  }
}
.top-sec__ttl {
  font-size: 1.428rem;
  font-weight: bold;
  line-height: 1;
  letter-spacing: 0.13em;
  text-align: center;
  @media (min-width: 768px) {
    font-size: 2.125rem;
  }
  span {
    color: #ed6d00;
    font-size: 1rem;
    display: block;
    margin-top: 0.526em;
    @media (min-width: 768px) {
      font-size: 1.187rem;
    }
  }
}
.top-sec__read {
  text-align: center;
  margin-top: 20px;
  @media (min-width: 768px) {
    margin-top: 30px;
  }
}
.top-mission__list {
  display: block;
  margin-top: 30px;
  margin: 30px auto 0;
  width: 95%;
  @media (min-width: 768px) {
    display: flex;
    margin-top: 60px;
    width: 100%;
  }
  li {
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    @media (min-width: 768px) {
      width: 33.33333%;
    }
    &:nth-child(1) {
      background-image: url(../img/top/mission_bg01.jpg);
    }
    &:nth-child(2) {
      background-image: url(../img/top/mission_bg02.jpg);
    }
    &:nth-child(3) {
      background-image: url(../img/top/mission_bg03.jpg);
    }
    &::before {
      content: "";
      display: block;
      padding-top: 100%;
    }
  }
}
.list__inner {
  background: #fff;
  text-align: center;
  opacity: 0.9;
  width: 53%;
  height: 53%;
  min-width: 160px;
  min-height: 160px;
  border-radius: 50%;
  margin: 0 auto;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  p {
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: 0.1em;
  }
}
.list-num {
  font-size: 1.687rem;
  line-height: 1;
  padding: 18% 0 20%;
  position: relative;
  &::after {
    content: "";
    background: #ed6d00;
    height: 2px;
    width: 24px;
    margin-left: -12px;
    position: absolute;
    bottom: 18%;
    left: 50%;
  }
}
.top-mission__txt {
  color: #fff;
  line-height: 1.5;
  width: 100%;
  padding: 0 10%;
  position: absolute;
  top: 72%;
  @media (max-width: 900px) {
    font-size: 0.75rem;
    top: 76%;
    //font-size: 0.875rem;
  }
}

/*============================
top project
============================*/

.top-sec--project {
  background-image: linear-gradient(
    to bottom,
    #e1e9ec 0%,
    #e1e9ec 70%,
    #ffffff 70%,
    #ffffff 100%
  );
}
.top-prpject__btn {
  text-align: center;
  padding: 0 0 30px;
}
.top-prpject__slider {
  position: relative;
}

//slick
.slider {
  margin: 0 auto;
  @media (min-width: 768px) {
    width: 90%;
    max-width: 980px;
  }
}
.slider__item {
  position: relative;
}
.slider__pict {
}
.slider__txt {
  color: #fff;
  position: absolute;
  top: 35%;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 90%;
  text-align: center;
}
.slider__ttl {
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 0.13em;
  margin-bottom: 0.5em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  @media (min-width: 520px) {
    font-size: 1.428rem;
    margin-bottom: 1em;
  }
  @media (min-width: 768px) {
    font-size: 1.687rem;
  }
}
.slider__read {
  letter-spacing: 0.1em;
  line-height: 1.8;
  height: 1rem * 1.8 * 2;
  overflow: hidden;
  position: relative;
}
.slick-slider {
  position: static !important;
}
.slick-dots {
  display: flex;
  margin-top: 15px;
  @media (min-width: 768px) {
    padding: 0 15px;
    margin-top: 25px;
  }
  li {
    opacity: 0.8;
    padding: 0 6px;
    &.slick-active {
      opacity: 1;
    }
  }
}
.slick-prev,
.slick-next {
  width: 15px;
  position: absolute;
  top: 33%;
  z-index: 1;
  @media (min-width: 768px) {
    width: auto;
    top: 35.69%;
  }
}
.slick-prev {
  left: 10px;
  @media (min-width: 768px) {
    left: 0;
  }
}
.slick-next {
  right: 10px;
  @media (min-width: 768px) {
    right: 0;
  }
}

/*============================
top info
============================*/

.top-sec-info {
  border-top: 1px solid #dedede;
  padding: 50px 0;
}
.top-sec-info--inner {
  position: relative;
}
.top-sec__ttl__info {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 20px;
}
.info--list {
  margin-bottom: 20px;
  @media (min-width: 768px) {
    margin: auto;
    padding-left: 15%;
    border-left: 1px solid #dedede;
  }
  time {
    width: 7em;
    float: left;
  }
  p {
    margin-left: 7em;
  }
}
.top-info__btn {
  margin: 0 auto;
  @media (min-width: 768px) {
    position: absolute;
    left: 15px;
    top: 50px;
  }
  .btn {
    padding-left: 25px;
    padding-right: 30px;
  }
}

/*============================
top blog
============================*/

.top-sec__ttl {
  margin-bottom: 1.875rem;
}
.top-sec--blog {
  background-color: #f2f2f2;
}
.blog-ttl {
  margin-bottom: 0.2em;
}
.blog__item {
  position: relative;
  margin-bottom: 40px;
  a {
    color: inherit;
    line-height: 1.2;
    font-weight: bold;
  }
  time {
    opacity: 0.5;
    font-size: 0.875rem;
    line-height: 1;
    display: block;
  }
  figure {
    overflow: hidden;
    padding-top: 60%;
    margin-bottom: 10px;
    position: relative;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      max-width: auto;
      transform: translate(-50%, -50%);
      transition: all 0.5s;
    }
    &:hover img {
      transform: translate(-50%, -50%) scale(1.1);
    }
  }
}
.cat {
  font-size: 0.812rem;
  color: #fff;
  background: #00968d;
  text-align: center;
  line-height: 1;
  padding: 4px 10px;
  min-width: 30%;
  position: absolute;
  top: 0;
  left: 0;
}
.top-blog__btn {
  text-align: center;
  margin-top: 30px;
}
