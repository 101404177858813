@charset "utf-8";

.projects-ttl{
  color: #E8E8E8;
  font-size: 2.857rem;
  font-weight: bold;
  letter-spacing: .13em;
  line-height: 1.2;
  text-align: right;
  padding-top: .1em;
	@media (min-width: 768px) {
    font-size: 5.937rem;
		background-size: cover;
		height:125px;
	}
}

.ttl-copy{
  font-size: 1.125rem;
  font-weight: bold;
  margin: 1.5em 0 0.3em;
  @media (min-width: 768px) {
    margin: 0 0 0.3em;
	}
  span{
    color: #00968D;
    margin: 0 .2em;
  }
}
.projects-more{
  text-align: center;
  @media (min-width: 768px) {
    text-align: left;
	}
}
