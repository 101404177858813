@charset "utf-8";

.blog {
  main {
    padding-top: 30px;
    margin-top: 60px;
    background: #f2f2f2;
    @media (min-width: 768px) {
      padding-top: 70px;
      margin-top: 120px;
    }
  }
}
.blog-pr {
  background: #fff;
  color: inherit;
  display: block;
  margin-bottom: 2.5rem;
  @media (min-width: 768px) {
    margin-bottom: 4.3rem;
  }
  &:hover {
    color: inherit;
    text-decoration: none;
  }
  time {
    opacity: 0.5;
    font-size: 0.875rem;
    line-height: 1;
    display: inline-block;
  }
  .blog-pr--pict {
    overflow: hidden;
    padding-top: 60%;
    position: relative;
    width: 100%;
    @media (min-width: 992px) {
      width: 67.593%;
      padding-top: 40.556%;
    }
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      max-width: auto;
      transform: translate(-50%, -50%);
      transition: all 0.5s;
    }
  }
  &:hover .blog-pr--pict img {
    transform: translate(-50%, -50%) scale(1.1);
  }
  .blog-pr--txt {
    background-color: #fff;
    padding: 10px 20px;
    @media (min-width: 992px) {
      padding: 10px 30px;
      width: calc(100% - 67.593%);
    }
  }
  .blog-pr--cat {
    display: inline-block;
    font-size: 0.812rem;
    color: #fff;
    background: #00968d;
    text-align: center;
    line-height: 1;
    padding: 4px 10px;
    min-width: 87px;
    margin-right: 1em;
  }
  .blog-pr-ttl {
    line-height: 1.2;
    font-size: 1.125rem;
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 10px;
  }
  @at-root .blog-pr-more {
    margin-bottom: 0.5em;
    -webkit-transition: all 0.2s;
    transition: all 0.3s;
    .blog-pr:hover & {
      margin-left: 5px;
      opacity: 0.6;
    }
    &::after {
      content: "";
      display: inline-block;
      width: 8px;
      height: 8px;
      border: 2px solid;
      border-color: transparent transparent #515151 #515151;
      -webkit-transform: rotate(-135deg);
      transform: rotate(-135deg);
      position: relative;
      top: -0.1em;
      margin-left: 3px;
    }
  }
}

.blog-nav--ttl {
  font-weight: bold;
  margin-bottom: 1.5em;
  line-height: 1;
  &:not(:first-child) {
    margin-top: 3em;
  }
}
.blog-nav--cat {
  a {
    background-color: #00968d;
    color: #fff;
    display: block;
    line-height: 1;
    padding: 0.5em;
    border-radius: 4px;
    transition: all 0.2s;
    &:hover {
      background-color: darken(#00968d, 5%);
      text-decoration: none;
    }
  }
  li:not(:last-child) {
    margin-bottom: 0.5em;
  }
}
.blog-nav--tag {
  letter-spacing: -0.4em;
  text-align: left;
  li {
    display: inline-block;
    letter-spacing: normal;
    &:not(:last-child) {
      &::after {
        content: "/";
        color: #959595;
        padding: 0 0.5em;
      }
    }
  }
}
.search-form {
  position: relative;
}
.search-form-submit {
  background: url(/img/blog/icon_search.svg) no-repeat center;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  margin: auto;
  width: 40px;
  height: 30px;
}

.pager {
  overflow: hidden;
  margin-bottom: 3rem;
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  li {
    margin: 1px;
    span,
    a {
      display: block;
      padding: 0.4em 1em;
      border-radius: 3px;
    }
    a {
      background: #eee;
      color: #515151;
      text-decoration: none;
    }
    a:hover {
      background: #515151;
      color: #fff;
    }
  }
  .current {
    background: #515151;
    color: #fff;
  }
}
.blog-list--ttl {
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 1em;
  @media (min-width: 992px) {
    font-size: 1.75rem;
  }
}

//ブログ詳細
.post {
  background: #fff;
}
.post__header__inr {
  padding: 1.875rem;
}
.post__pict {
  position: relative;
  overflow: hidden;
  padding-top: 60%;
  img {
    width: 100%;
    height: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
}
.post__time {
  opacity: 0.5;
  font-size: 0.875rem;
  line-height: 1;
  display: inline-block;
}
.post__cat {
  display: inline-block;
  font-size: 0.812rem;
  color: #fff;
  background: #00968d;
  text-align: center;
  line-height: 1;
  padding: 4px 10px;
  min-width: 87px;
  margin-right: 1em;
}
.post__ttl {
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.3;
}
.post__content {
  margin: 1.875rem 1.875rem 0;
  padding-bottom: 1.875rem;
  h2 {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 2em 0 1em;
  }
  h3 {
    font-size: 1.125rem;
    font-weight: bold;
    margin: 2em 0 1em;
  }
}

//一覧へボタン
.blog-btn {
  &::after {
    border: 0;
  }
  &:hover {
    &::after {
      border: 0;
    }
  }
}
