@charset "utf-8";
// ================================
// CSS information
//  file name  :base.scss
//  style info :reset rebootの上書き用
// ================================

/* reset */
blockquote, dd, dl, dt, figure, h1, h2, h3, h4, h5, h6, li, ol, p, table, ul {
	margin: 0;
	padding: 0
}
dl, ol, ul {
	list-style: none
}
h1, h2, h3, h4, h5, h6 {
	-webkit-font-feature-settings: "palt";
	font-feature-settings: "palt";
	font-size: 100%;
	font-weight: 400;
	line-height: 1.25
}
button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	padding: 0;
	border: none;
	background-color: transparent;
	outline: none
}
img {
    vertical-align: bottom;
    max-width: 100%;
    height: auto;
}

/* reboot */
html {
	font-size: 0.875rem;
	@media (min-width: 768px) {
		font-size: 1rem;
	}
}
body {
	font-family: "Roboto", sans-serif;
	font-size: inherit;
	line-height: 1.8;
	color: #515151;
	text-align: justify;
}
a {
	color: #ED6D00;
  }
a:hover {
	color: #ED6D00;
}
p{
	margin-bottom: 1em;
}