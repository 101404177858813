@charset "utf-8";

.support-plan{
  margin-bottom: 4.24%;
	@media (min-width: 768px) {
    margin-bottom: auto;
	}
}
.support-plan__ttl{
  background: #00968D;
  color: #fff;
  font-size: 1.25rem;
  font-weight: bold;
  text-align: center;
  padding: 0.75em 10px;
  &.support-plan__ttl--corp{
    background: #ED6D00;

  }
}
.support-plan__inr{
  background: #F2F2F2;
  padding: 1.8rem 9.25%;
}

.support-plan-s{
  background: #F2F2F2;
  width: 100%;
  margin-bottom: 4.24%;
  small{
    display: block;
    text-align: center;
  }
	@media (min-width: 768px) {
    margin-bottom: 0;
	}
  dd{
    padding: 6% 9.25%;
    &.support-plan-s__price{
      padding-left: 0;
      padding-right: 0;
    }
  }
}
.support-plan-s__ttl{
  background: #00968D;
  color: #fff;
  font-size: 1.125rem;
  font-weight: bold;
  line-height: 1.2;
  text-align: center;
  padding: 0.75em 10px;
  min-height: 48px;
  &.support-plan-s__ttl--s{
    font-size: 0.9rem;
    padding: 0.5em 10px;
  }
}
.support-plan-s__price{
  background: #FAFAFA;
  text-align: center;
  line-height: 1;
  font-size: 1.125rem;
  font-weight: bold;
}
.support-price{
  background: #00968D;
  color: #fff;
  font-size: 1.125rem;
  font-weight: bold;
  text-align: center;
  padding: .2em 10px;
  margin-bottom: 20px;
}
.support-merit{
  display: table;
  width: 100%;
  margin-bottom: 4.24%;
	@media (min-width: 768px) {
    margin-bottom: 0;
	}
  dt{
    display: table-cell;
    background: #F2F2F2;
    text-align: center;
    vertical-align: middle;
    width: 20%;
    min-width: 52px;
  }
  dd{
    font-size: 0.875rem;
    font-weight: bold;
    display: table-cell;
    background: #FAFAFA;
    padding: 3%;
    li{
      position: relative;
      padding-left: 1.2em;
      &::before {
        content:  "●";
        color: #00968D;
        display: block;
        font-size: 0.812rem;
        position: absolute;
        top: 0.1em;
        left: 0;
      }
    }
  }
}

.support-case__box{
  p{
    font-size: 0.937rem;
  }
  &:not(:last-child){
    margin-bottom: 8.22%;
    @media (min-width: 768px) {
      margin-bottom: 0;
    }
  }
}
.support-case__pict{
  margin-bottom: 1em;
}

.support-sponsor{
  text-align: center;
  li{
    margin-bottom: 1em;
  }
}
.support-sponsor__logo{
  border: 1px solid #E8E8E8;
  margin-bottom: .5em;
}
.support-sponsor__name{
  font-size: 0.75rem;
  line-height: 1.2;
  margin-bottom: .5em;
}
.support-sponsor__since{
  font-size: 0.625rem;
  line-height: 1.2;
}
.support-table{
  width: 100%;
  margin-bottom: 2rem;
  @media (min-width: 768px) {
    border-left: 5px solid #00968D;
  }
  th,td{
    display: block;
    line-height: 1.2;
    padding: 1em;
    width: 100%;
    @media (min-width: 768px) {
      display: table-cell;
      border: 1px solid #B7B7B7;
      border-width: 1px 1px 1px 0;
      width: auto;
    }
  }
  th{
    padding-left: 0;
    font-size: 1.3rem;
    @media (min-width: 768px) {
      padding-left: 1em;
      font-size: 1rem;
      width: 30%;
    }
  }
  .bg-green{
    background: #B6E3E0;
  }
  .bg-gray{
    background: #F2F2F2;
  }
}