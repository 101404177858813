@charset "utf-8";

.header {
  background: transparent;
  color: #fff;
  top: 0;
  left: 0;
  z-index: 1;
  padding: 0 20px;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 100;
  transition: all 0.2s ease-out;
  @media (min-width: 768px) {
    padding: 0 30px;
    height: 120px;
  }
  a {
    &:hover {
      text-decoration: none;
    }
  }
}
.h-logo {
  width: 70px;
  img {
    width: 100%;
  }
  @media (min-width: 768px) {
    width: 120px;
    transition: all 0.2s;
  }
}
.gnav {
  @media (max-width: 1020px) {
    display: none;
  }
}
.gnav-item {
  float: left;
  padding-right: 20px;
  &:last-child {
    padding: 0;
  }
  @at-root .home & a {
    color: #fff;
  }
  a {
    color: #515151;
    font-weight: bold;
    transition: all 0.2s;
    &:hover {
      opacity: 0.7;
    }
  }
}

.h-contact {
  display: flex;
  justify-content: flex-end;
  @media (max-width: 1020px) {
    display: none;
  }
}
.h-support {
  a {
    color: #00968d;
    font-weight: bold;
    &::before {
      content: url(../img/common/h_icon_support.svg);
      display: inline-block;
      position: relative;
      top: 3px;
    }
  }
}
.h-mail {
  a {
    color: #fff;
    background: #ed6d00;
    padding: 15px;
    &::before {
      content: url(../img/common/h_icon_mail.svg);
    }
  }
}

.header.is-animation {
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  height: 74px;
  .h-logo {
    @media (min-width: 768px) {
      width: 100px;
    }
  }
  .gnav-item {
    a {
      color: #515151;
    }
  }
  .h-support {
    a {
      color: #00968d;
      &::before {
        content: url(../img/common/h_icon_support.svg);
        display: inline-block;
        position: relative;
        top: 3px;
      }
    }
  }
}

//sp_menu
.sp-menu {
  display: none;
  @media (max-width: 1020px) {
    display: block;
  }
}
.menu-trigger.active {
  background: none;
  span {
    background: #fff;
  }
}
.menu-trigger,
.menu-trigger span {
  display: inline-block;
  transition: all 0.4s;
  box-sizing: border-box;
  cursor: pointer;
}
.menu-trigger {
  position: absolute;
  top: 50%;
  right: 20px;
  width: 40px;
  height: 40px;
  z-index: 9999;
  margin-top: -20px;
}
.menu-trigger span {
  position: absolute;
  background: #333;
  width: 25px;
  height: 2px;
  left: 0;
  right: 0;
  margin: auto;
  @at-root .home & {
    background: #fff;
  }
}
.menu-trigger span:nth-of-type(1) {
  top: 10px;
}
.menu-trigger span:nth-of-type(2) {
  top: -3px;
  bottom: 0;
}
.menu-trigger span:nth-of-type(3) {
  bottom: 12px;
}
.menu-trigger.active span:nth-of-type(1) {
  -webkit-transform: translateY(9px) rotate(-45deg);
  transform: translateY(9px) rotate(-45deg);
}
.menu-trigger.active span:nth-of-type(2) {
  opacity: 0;
}
.menu-trigger.active span:nth-of-type(3) {
  -webkit-transform: translateY(-5px) rotate(45deg);
  transform: translateY(-5px) rotate(45deg);
  bottom: 14px;
}
.sp-gnav {
  display: none;
  position: absolute;
  z-index: 9998;
  top: 0;
  left: 0;
  background: rgba(29, 65, 115, 0.9);
  width: 100%;
}
.sp-gnav .list {
  padding: 90px 20px;
}
.sp-gnav .list .item a {
  display: block;
  text-align: center;
  text-decoration: none;
  color: #fff;
  padding: 15px 0;
}

.header.is-animation {
  .menu-trigger span {
    background: #333;
  }
}
