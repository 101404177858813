@charset "utf-8";
/*----------------------------------------------------
	汎用class
----------------------------------------------------*/

//表示非表示
.only-sp {
	@media screen and (min-width: 768px) {
	  display: none !important;
	}
  }
  .only-pc {
	display: none !important;
	@media screen and (min-width: 768px) {
	  display: block !important;
	}
  }

//typography
.bold{
	font-weight:bold;
}
.tac{
	text-align: center;
}
.tal{
	text-align: left;
}
.tar{
	text-align: right;
}
.vat{
	vertical-align:top;
}
.vam{
	vertical-align:middle;
}
.vab{
	vertical-align:bottom;
}
.mt10{
	margin-top:10px !important;
}
.mt20{
	margin-top:20px !important;
}
.mt30{
	margin-top:30px !important;
}
.mt40{
	margin-top:40px !important;
}
.mt50{
	margin-top:50px !important;
}
.mb10{
	margin-bottom:10px !important;
}
.mb20{
	margin-bottom:20px !important;
}
.mb30{
	margin-bottom:30px !important;
}
.mb40{
	margin-bottom:40px !important;
}
.mb50{
	margin-bottom:50px !important;
}