@charset "utf-8";
/*----------------------------------------------------------------
Theme Name: The C.W Nicol Afan Woodland Trust
Theme URI:
Description: The C.W Nicol Afan Woodland Trust
Author: Namaco lab
Author URI:
Version:
----------------------------------------------------------------*/
// ================================
// CSS information
//  file name  :style.css
//  style info :各cssファイル読み込み用
// ================================

// 変数,mixinの管理
//@import "vars";

//mixinの管理
//@import "mixin";


// -----------------------------------------------------------------
// plugin
// -----------------------------------------------------------------


// ==========================================================================
// Foundation
// ==========================================================================

@import "foundation/_bootstrap-reboot";
@import "foundation/_base";

// ==========================================================================
// Layout
// ==========================================================================

@import "layout/_footer";
@import "layout/_header";
@import "layout/_main";
//@import "layout/_sidebar";

// -----------------------------------------------------------------
// Utility
// -----------------------------------------------------------------

@import "object/utility/_common";

// ==========================================================================
// Object
// ==========================================================================

// -----------------------------------------------------------------
// Component
// -----------------------------------------------------------------

@import "object/component/_bootstrap-grid";
@import "object/component/_grid";
@import "object/component/_btn";
@import "object/component/_bootstrap-form";
@import "object/component/_form";

// -----------------------------------------------------------------
// Project
// -----------------------------------------------------------------

@import "object/project/_top";
@import "object/project/_page";
@import "object/project/_about";
@import "object/project/_projects";
@import "object/project/_support";
@import "object/project/_blog";
