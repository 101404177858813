@charset "utf-8";

.page-wrap {
  position: relative;
  z-index: 100;
  background: url(../img/top/bg_works.png) no-repeat center bottom #fff;
  padding: 50px 0 100px;
  @media (max-width: 767px) {
    background: #fff;
    padding: 20px 0 0;
    padding-bottom: 0;
  }
}
.page-wrap.page-wrap--service {
  padding: 0 0 100px;
}
.page {
  margin-top: 60px;
  @media (min-width: 768px) {
    margin-top: 120px;
  }
}
.page-header {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
  &.page-header--about {
    background-image: url(../img/about/page_header_bg.png);
  }
  &.page-header--projects {
    background-image: url(../img/projects/page_header_bg.png);
  }
  &.page-header--supporter {
    background-image: url(../img/supporter/page_header_bg.png);
  }
  @media (min-width: 768px) {
    height: 660px;
  }
}
.page-header__ttl {
  color: #fff;
  font-size: 1.428rem;
  font-weight: bold;
  line-height: 1;
  letter-spacing: 0.13em;
  text-align: center;
  text-shadow: 0 0 6px #737373;
  @media (min-width: 768px) {
    font-size: 2.125rem;
    margin-top: -60px;
  }
  span {
    color: #fff;
    font-size: 1rem;
    display: block;
    margin-top: 0.526em;
    @media (min-width: 768px) {
      font-size: 1.187rem;
    }
  }
}
.page-box {
  background: #fff;
  padding: 30px 20px;
  @media (min-width: 768px) {
    width: 80%;
    max-width: 750px;
    padding: 40px 60px 80px;
    margin: -60px auto 0;
  }
}
.ttl {
  font-size: 1.571rem;
  font-weight: bold;
  line-height: 1;
  letter-spacing: 0.13em;
  text-align: center;
  margin-bottom: 1.07em;
  &.ttl--projects {
    text-align: left;
  }
  @media (min-width: 768px) {
    font-size: 1.75rem;
    &.ttl--projects {
      text-align: left;
    }
    &.ttl--support {
      text-align: left;
      margin-bottom: 0.5em;
    }
  }
  span {
    color: #ed6d00;
    font-size: 1rem;
    display: block;
    margin-top: 0.526em;
    @media (min-width: 768px) {
      font-size: 1.187rem;
    }
  }
}
.ttl2 {
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.2;
  margin: 2em 0 1em;
  @media (min-width: 768px) {
    margin: 3em 0 1.5em;
  }
}
.ttl3 {
  display: block;
  font-size: 1.125rem;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 1em;
}
.sec {
  padding-bottom: 9.259%;
  //projects
  &.sec--projects {
    section:not(:last-of-type) {
      padding-bottom: 7.4%;
    }
  }
  &.sec--projects:nth-child(2n + 1) {
    background-color: #ededed;
    .projects-ttl {
      color: #fff;
    }
  }
}
.txt-box {
  margin-bottom: 1em;
}