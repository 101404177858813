@charset "utf-8";
.f-nav {
	li{
		a{
			text-decoration:none;
			color:inherit;
			&:hover{
				text-decoration:underline;
			}
		}
	}
}
.footer{
	background: url(../img/common/f_bg.png) no-repeat center bottom #e8e8e8;
	background-size: cover;
	padding: 40px 0;
	@media (min-width: 768px) {
		padding: 100px 0;
	}
}
.f-info{
	dl{
		text-align: center;
		@media (min-width: 768px) {
			text-align: left;
		}
	}
	dt{
		font-size: 0.937rem;
		margin-bottom: .3em;
		@media (min-width: 768px) {
			margin-bottom: .8em;
		}
	}
	dd{
		font-size: 0.812rem;
	}
}
.f-info__ttl{
	color: #ED6D00;
	text-align: center;
	font-size: 1.187rem;
	margin-bottom: 1em;
	@media (min-width: 768px) {
		text-align: left;
		margin-bottom: 2em;
	}
}
.f-nav{
	overflow: hidden;
	border: #ccc solid;
	border-width: 1px 0;
    padding: 40px 0 29px;
	margin: 26px 0 40px;
	@media (min-width: 768px) {
		padding: 0;
		margin: 0;
		border: 0;
	}
	ul{
		float: left;
		@media (min-width: 768px) {
			float: none;
		}
	}
	& ul:nth-child(1){
		width: 40%;
		@media (min-width: 768px) {
			width: auto;
		}
	}
	& ul:nth-child(2){
		width: 60%;
		@media (min-width: 768px) {
			width: auto;
		}
	}
	li{
		margin-bottom: .8em;
	}
}
.f-followus{
	overflow: hidden;
}
.f-followus__list{
	display: flex;
	justify-content: space-around;
	padding: 0 10%;
	li{
		font-size: 1.687rem;
		line-height: 1;
		margin-bottom: 1.111em;
		a{
			color: inherit;
		}
	}
}
.f-followus__fb {
    text-align: center;
}
.copy {
	text-align:center;
	margin-top:50px;
	@media (max-width: 767px) {
		font-size:1.1rem;
	}
}