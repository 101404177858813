@charset "utf-8";

.btn-pdf {
  color: #fff;
  display: inline-block;
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.2;
  text-align: center;
  text-indent: -1em;
  position: relative;
  vertical-align: middle;
  width: 100%;
  padding: 0.6em;
  &::after {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    border: 2px solid;
    border-color: transparent transparent #fff #fff;
    transform: rotate(-135deg);
    margin-top: -5px;
    position: absolute;
    top: 50%;
    right: 15px;
    transition: all 0.2s;
  }
  &::before {
    content: url(../img/common/icon_pdf.svg);
    display: inline-block;
    vertical-align: middle;
    padding-right: 0.5em;
  }
  &.btn-pdf--a {
    background: #ed6d00;
  }
  &.btn-pdf--b {
    background: #00968d;
  }
  &:hover {
    color: #fff;
    text-decoration: none;
    opacity: 0.8;
  }
}
.btn-solid {
  color: #fff;
  display: inline-block;
  font-size: 1.187rem;
  font-weight: bold;
  line-height: 1.2;
  text-align: center;
  position: relative;
  vertical-align: middle;
  width: 100%;
  padding: 0.6em;
  &::after {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    border: 2px solid;
    border-color: transparent transparent #fff #fff;
    transform: rotate(-135deg);
    margin-top: -5px;
    position: absolute;
    top: 50%;
    right: 15px;
    transition: all 0.2s;
  }
  &.btn-solid--a {
    background: #fff;
    color: #ed6d00;
    &::after {
      content: "";
      border-color: transparent transparent #ed6d00 #ed6d00;
    }
    &:hover {
      text-decoration: none;
      opacity: 0.8;
    }
  }
  &.btn-solid--b {
    background: #fff;
    color: #00968d;
    &::after {
      content: "";
      border-color: transparent transparent #00968d #00968d;
    }
    &:hover {
      text-decoration: none;
      opacity: 0.8;
    }
  }
  &.btn-solid--c {
    background: #ed6d00;
    transition: all 0.2s;
    color: #fff;
    &::after {
      content: "";
      border-color: transparent transparent #fff #fff;
    }
    &:hover {
      background-color: darken(#ed6d00, 3%);
      text-decoration: none;
    }
  }
}
.btn-area {
  text-align: center;
  @at-root .btn {
    color: #ed6d00;
    font-size: 0.937rem;
    font-weight: 600;
    line-height: 1;
    text-align: center;
    display: inline-block;
    padding: 12px 70px;
    border: #ed6d00 1px solid;
    position: relative;
    cursor: pointer;
    transition: all 0.2s;
    &::after {
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      border: 2px solid;
      border-color: transparent transparent #ed6d00 #ed6d00;
      transform: rotate(-135deg);
      margin-top: -5px;
      position: absolute;
      top: 50%;
      right: 15px;
      transition: all 0.2s;
    }
    &:hover {
      color: #fff;
      background: #ed6d00;
      text-decoration: none;
      &::after {
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        border: 2px solid;
        border-color: transparent transparent #fff #fff;
        transform: rotate(-135deg);
        margin-top: -5px;
        transition: all;
        position: absolute;
        top: 50%;
        right: 15px;
      }
    }
    & + .btn {
      margin-left: 20px;
    }
  }
}
