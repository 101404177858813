.form-control--about {
  color: inherit;
  border: 2px solid #515151;
  border-radius: 0;
}
select.form-control--about {
  background: url(../img/common/ico_select.png) right center no-repeat #fff !important;
  -webkit-appearance: none;
}
.form-control--about:focus {
  border-color: inherit;
  box-shadow: 0 0 0 0.2rem inherit;
}
.form-control--w50 {
  max-width: 50px;
}
.form-control--w70 {
  max-width: 70px;
}
.form-control--w100 {
  max-width: 100px;
}
.invalid-feedback {
  display: block;
}
.form-check-input {
  margin-top: 0.05rem;
}